<script setup>
import { ref, useAnimatedHeader } from '#imports';

const props = defineProps({
  isHideFooter: { type: Boolean, default: false },
  isHeaderShown: { type: Boolean, default: false },
  isAnimatedHeader: { type: Boolean, default: false },
  isShadyHeader: { type: Boolean, default: false },
  isTransparentHeader: { type: Boolean, default: false },
  forceTransparentHeader: { type: Boolean, default: false },
  isHideDonationForm: { type: Boolean, default: false },
  isHideSubscriptionBlock: { type: Boolean, default: false },
  isShowOnlySocials: { type: Boolean, default: false },
  isPride: { type: Boolean, default: false },
});

const headerElement = ref();

if (props.isAnimatedHeader && !process.server) {
  useAnimatedHeader(headerElement);
}
</script>

<template>
  <div class="default-layout">
    <div class="default-layout__header-card">
      <slot name="header-card" />
    </div>

    <div
      v-if="isHeaderShown"
      ref="headerElement"
      class="default-layout__header"
    >
      <TheHeaderSpecial v-if="isPride" />
      <TheHeader
        v-if="!isPride"
        :force-transparent="forceTransparentHeader"
        :is-colored-logo="false"
        :is-shady="isShadyHeader"
        :is-transparent="isTransparentHeader"
      />
      <slot name="progress-bar" />
      <slot name="mobile-progress-bar" />
    </div>

    <slot />

    <TheFooter
      v-if="!isHideFooter"
      :is-hide-donation-form="isHideDonationForm"
      :is-hide-subscription-block="isHideSubscriptionBlock"
      :is-show-only-socials="isShowOnlySocials"
    />
  </div>
</template>

<style lang="postcss" scoped>
.default-layout {
  position: relative;
  background: var(--page-background-color);

  /* hack for remove header flickering on Safari/iOS */
  @supports (-webkit-hyphens: none) {
    transform: translateZ(0);
  }

  &__header-card {
    position: relative;
    z-index: 1;
  }

  &__header {
    position: sticky;
    z-index: calc(var(--modal-z-index) - 1);
    top: 0;
    transition: var(--default-transition) top;
  }

  &__header--static {
    position: relative;
  }
}
</style>
